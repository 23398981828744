<template>
  <div class="patient page">
    <NavMenu>
      <div class="right-warp">
        <div class="right-content">
          <h2 class="content-title">
            <div class="title">
              <img src="../../assets/img/icon_6.png" alt="ico-title" />
              <span>{{$t('account.change')}}</span>
            </div>
            <div class="mark">
              <span class="sig">*</span>
              <span>{{$t('common.mark')}}</span>
            </div>
          </h2>
            <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="auto"
            class="form"
          >
            <div class="form-left">
              <el-form-item :label="$t('account.original')+':'" prop="old"
                ><el-input type="password"  v-model="ruleForm.old"></el-input
              ></el-form-item>
              <el-form-item :label="$t('account.new')+':'" prop="newpwd"
                ><el-input type="password" v-model="ruleForm.newpwd"></el-input
              ></el-form-item>
              <el-form-item :label="$t('account.confirm')+':'" prop="surepwd"
                ><el-input type="password" v-model="ruleForm.surepwd"></el-input
              ></el-form-item>
             
            </div>
         
            
          </el-form>
          <div class="foot">
              <input
                type="button"
                :value="$t('common.save')"
                class="btn-submit"
                @click="submitForm('ruleForm')"
              />
             
            </div>
        </div>
      </div>
    </NavMenu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        old: "",
        newpwd: "",
        surepwd: "",
      },
      rules: {
        old: [
          { required: true, message: this.$t('account.message1'), trigger: "blur" },
        ],
        newpwd: [
          { required: true, message: this.$t('account.message2'), trigger: "blur" },
        ],
        surepwd: [{ required: true, message: this.$t('account.message3'), trigger: "blur" }],
      },
    };
  },
  methods: {
    //提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //表单验证通过
          this.$confirm(this.$t('account.warn1'), this.$t('common.warn'), {
          	confirmButtonText: this.$t('common.confirm'),
          	cancelButtonText: this.$t('common.cancel'),
          	type: 'warning'
          }).then(() => {
          	this.$api.change_pwd(this.ruleForm).then(res => {
          			if (res.code == 1) {
          				this.ruleForm.old = "";
          				this.ruleForm.newpwd = "";
          				this.ruleForm.surepwd = "";
                  return this.$message.success(res.msg);
          			}
                this.$message.error(res.msg);
          		})
          		
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

  },
};
</script>
<style scoped>
.right-content {
  height: 757px;
  position: relative;
}
.content-title .mark {
  font-family: FZZhunYuan-M02S;
  font-weight: 400;
  line-height: 159px;
  font-size: 13px;
}
.sig {
  color: #ff0000;
}
.right-content .form {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}
.right-content .form-left,
.right-content .form-right {
  width: 45%;
}
.right-content .form .foot {
  /* position: absolute; */
  /* bottom: 25px; */
  /* left: 0; */
  /* right: 0; */
  text-align: center;
}
.right-content .form .foot input {
  margin: 0 12px;
}
.btn {
  background: #fff;
   margin-left: 30px;
}
.control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;
  margin-top: 20px;
}
.control .control-left {
  display: flex;
  align-items: center;
}




</style>
